<script setup>

import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {computed, onMounted} from 'vue';
import {stopIdleTracking} from "@/services/idleAppLockTimer";

/* -----------------------------
 * Variables & Properties
 * -----------------------------
 * */
const router = useRouter();
const store = useStore();

/* -----------------------------
 * Computed Properties
 * -----------------------------
 * */
const userFirstName = computed(()=> {
    if(store.state.auth.user){
        return store.state.auth.user.name.toString().split(" ")[0];
    }
    return "";
})



/* -----------------------------
 * Methods
 * -----------------------------
 * */
function logout(){
    //clear token & user details from store & local storage
    store.commit('auth/LOGOUT');
    //redirect user to login page
    router.replace({name: 'login'});
    //stop idle time tracker
    stopIdleTracking();
}

</script>

<template>

    <header id="header" class="header fixed-top d-flex align-items-center">

        <div class="d-flex align-items-center justify-content-between">
            <router-link :to="{name:'dashboard'}" class="logo d-flex align-items-center">
                <img src="@/assets/images/myzola_icon.png" alt="">
                <span class="d-none d-lg-block">MyZola Admin</span>
            </router-link>
            <i class="bi bi-list toggle-sidebar-btn"></i>
        </div><!-- End Logo -->

        <div class="search-bar">
            <form class="search-form d-flex align-items-center" method="POST" action="#">
                <input type="text" name="query" placeholder="Search" title="Enter search keyword">
                <button type="submit" title="Search"><i class="bi bi-search"></i></button>
            </form>
        </div><!-- End Search Bar -->

        <nav class="header-nav ms-auto">
            <ul class="d-flex align-items-center">

                <li class="nav-item d-block d-lg-none">
                    <a class="nav-link nav-icon search-bar-toggle " href="#">
                        <i class="bi bi-search"></i>
                    </a>
                </li><!-- End Search Icon-->

<!--                <li class="nav-item dropdown">-->

<!--                    <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">-->
<!--                        <i class="bi bi-bell"></i>-->
<!--                        <span class="badge bg-primary badge-number">4</span>-->
<!--                    </a>&lt;!&ndash; End Notification Icon &ndash;&gt;-->

<!--                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">-->
<!--                        <li class="dropdown-header">-->
<!--                            You have 4 new notifications-->
<!--                            <a href="#"><span class="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <hr class="dropdown-divider">-->
<!--                        </li>-->

<!--                        <li class="notification-item">-->
<!--                            <i class="bi bi-exclamation-circle text-warning"></i>-->
<!--                            <div>-->
<!--                                <h4>Lorem Ipsum</h4>-->
<!--                                <p>Quae dolorem earum veritatis oditseno</p>-->
<!--                                <p>30 min. ago</p>-->
<!--                            </div>-->
<!--                        </li>-->

<!--                        <li>-->
<!--                            <hr class="dropdown-divider">-->
<!--                        </li>-->

<!--                        <li class="notification-item">-->
<!--                            <i class="bi bi-x-circle text-danger"></i>-->
<!--                            <div>-->
<!--                                <h4>Atque rerum nesciunt</h4>-->
<!--                                <p>Quae dolorem earum veritatis oditseno</p>-->
<!--                                <p>1 hr. ago</p>-->
<!--                            </div>-->
<!--                        </li>-->

<!--                        <li>-->
<!--                            <hr class="dropdown-divider">-->
<!--                        </li>-->

<!--                        <li class="notification-item">-->
<!--                            <i class="bi bi-check-circle text-success"></i>-->
<!--                            <div>-->
<!--                                <h4>Sit rerum fuga</h4>-->
<!--                                <p>Quae dolorem earum veritatis oditseno</p>-->
<!--                                <p>2 hrs. ago</p>-->
<!--                            </div>-->
<!--                        </li>-->

<!--                        <li>-->
<!--                            <hr class="dropdown-divider">-->
<!--                        </li>-->

<!--                        <li class="notification-item">-->
<!--                            <i class="bi bi-info-circle text-primary"></i>-->
<!--                            <div>-->
<!--                                <h4>Dicta reprehenderit</h4>-->
<!--                                <p>Quae dolorem earum veritatis oditseno</p>-->
<!--                                <p>4 hrs. ago</p>-->
<!--                            </div>-->
<!--                        </li>-->

<!--                        <li>-->
<!--                            <hr class="dropdown-divider">-->
<!--                        </li>-->
<!--                        <li class="dropdown-footer">-->
<!--                            <a href="#">Show all notifications</a>-->
<!--                        </li>-->

<!--                    </ul>&lt;!&ndash; End Notification Dropdown Items &ndash;&gt;-->

<!--                </li>-->
                <!-- End Notification Nav -->

<!--                <li class="nav-item dropdown">

                    <a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                        <i class="bi bi-chat-left-text"></i>
                        <span class="badge bg-success badge-number">3</span>
                    </a>&lt;!&ndash; End Messages Icon &ndash;&gt;

                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
                        <li class="dropdown-header">
                            You have 3 new messages
                            <a href="#"><span class="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li class="message-item">
                            <a href="#">
                                <img src="assets/img/messages-1.jpg" alt="" class="rounded-circle">
                                <div>
                                    <h4>Maria Hudson</h4>
                                    <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                                    <p>4 hrs. ago</p>
                                </div>
                            </a>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li class="message-item">
                            <a href="#">
                                <img src="assets/img/messages-2.jpg" alt="" class="rounded-circle">
                                <div>
                                    <h4>Anna Nelson</h4>
                                    <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                                    <p>6 hrs. ago</p>
                                </div>
                            </a>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li class="message-item">
                            <a href="#">
                                <img src="assets/img/messages-3.jpg" alt="" class="rounded-circle">
                                <div>
                                    <h4>David Muldon</h4>
                                    <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                                    <p>8 hrs. ago</p>
                                </div>
                            </a>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li class="dropdown-footer">
                            <a href="#">Show all messages</a>
                        </li>

                    </ul>&lt;!&ndash; End Messages Dropdown Items &ndash;&gt;

                </li>-->
                <!-- End Messages Nav -->

                <li class="nav-item dropdown pe-3">

                    <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                        <img src="@/assets/images/default_user_avatar.png" alt="Profile" class="rounded-circle">
                        <span class="d-none d-md-block dropdown-toggle ps-2">{{ userFirstName || '' }}</span>
                    </a><!-- End Profile Iamge Icon -->

                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                        <li class="dropdown-header">
                            <h6>{{ store.state.auth.user?.name || "" }}</h6>
                            <span>Admin</span>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <!--<li>
                            <a class="dropdown-item d-flex align-items-center" href="users-profile.html">
                                <i class="bi bi-person"></i>
                                <span>My Profile</span>
                            </a>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li>
                            <a class="dropdown-item d-flex align-items-center" href="users-profile.html">
                                <i class="bi bi-gear"></i>
                                <span>Account Settings</span>
                            </a>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li>
                            <a class="dropdown-item d-flex align-items-center" href="pages-faq.html">
                                <i class="bi bi-question-circle"></i>
                                <span>Need Help?</span>
                            </a>
                        </li>-->
                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li>
                            <a @click="logout" class="dropdown-item d-flex align-items-center" href="javascript:void">
                                <i class="bi bi-box-arrow-right"></i>
                                <span>Sign Out</span>
                            </a>
                        </li>

                    </ul><!-- End Profile Dropdown Items -->
                </li><!-- End Profile Nav -->

            </ul>
        </nav><!-- End Icons Navigation -->

    </header>

</template>

<style scoped>

</style>